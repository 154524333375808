.bar {
	background: linear-gradient(103.98deg, #fff 0%, rgba(0, 160, 220, 0.3) 58.81%, rgba(0, 160, 220, 0) 97.65%),
		url("../images/bar-bg.webp") center / cover no-repeat;
	padding: 155px 0 52px 0;
	@media (max-width: 992px) {
		padding: 120px 0 40px 0;
	}
	@media (max-width:767px) {
		padding: 100px 0 40px 0;
	}
	@media (max-width:500px) {
		padding: 90px 0 40px 0;
	}

	&__subtitle {
		&.icons-pin{
			&:before{
				padding-right: 8px;
			}
		}
	}

	&__object {
		font-weight: 300;
		font-size: 16px;
		line-height: 170%;
		color: #ffffff;
		span{
			display: inline-block;
		}
	}
}
.breadcrumbs {
	margin-bottom: 46px;
	@media (max-width: 992px) {
		margin-bottom: 20px;
	}
	@media (max-width:767px) {
		margin-bottom: 10px;
	}
	&__list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	&__item {
		&:not(:last-child) {
			&:after {
				content: " / ";
				color: var(--black);
				font-weight: normal;
				font-size: 13px;
				line-height: 130%;
				display: inline-block;
				padding: 0 5px;
			}
		}
	}

	&__link {
		color: var(--black);
		font-weight: normal;
		font-size: 13px;
		line-height: 200%;
		text-decoration: underline;
	}

	&__span {
		color: var(--black);
		font-weight: normal;
		font-size: 13px;
		line-height: 200%;
	}
}
