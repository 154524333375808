.gallery {
	padding: 60px 0 80px 0;
	@media (max-width:767px) {
		padding: 0;
	}
	&__body{
		@media (max-width:767px){
			margin: 0 -20px;
		}
	}

	&__list {
		display: grid;
		gap: 30px;
		grid-template-columns: repeat(auto-fit,minmax(160px, 1fr));
		@media (max-width:1200px) {
			gap: 20px;
			grid-template-columns: repeat(auto-fit,minmax(260px, 1fr));
		}
		@media (max-width:992px) {
			gap: 10px;
		}
		@media (max-width:767px) {
			gap: 0;
		}
		@media (max-width:500px) {
			grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
		}
	}

	&__item {
		position: relative;
		min-height: 160px;
		&:nth-of-type(11n + 1){
			grid-column: span 2;
			grid-row: span 2;
		}
		&:nth-of-type(11n + 2){
			grid-column: span 2;
			grid-row: span 1;
		}
		&:nth-of-type(11n + 3){
			grid-column: span 2;
			grid-row: span 1;
		}
		&:nth-of-type(11n + 4){
			grid-column: span 4;
			min-height: 320px;
		}
		&:nth-of-type(11n + 9){
			grid-column: span 2;
			grid-row: span 1;
		}
		&:nth-of-type(11n + 10){
			grid-column: span 4;
		}
		&:nth-of-type(11n + 11){
			grid-column: span 2;
		}
		@media (max-width:1200px) {
			min-height: 160px;
			&:nth-of-type(n){
				grid-column: span 1;
				grid-row: span 1;
				min-height: 160px;
			}
		}
		@media (max-width:767px) {
			min-height: auto;
			padding: 0px 0px 100% 0px;
			&:nth-of-type(n){
				grid-column: span 1;
				grid-row: span 1;
				min-height: auto;
			}
		}
	}

	&__link {
		display: block;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}