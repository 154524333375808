.object {
	padding: 50px 0 60px 0;

	&__description {
		display: flex;
		margin-bottom: 50px;
		@media (max-width: 992px) {
			flex-direction: column;
			margin-bottom: 30px;
		}
	}

	&__sliders {
		min-width: 0;
		flex: 0 0 560px;
		margin-right: 30px;
		@media (max-width: 1024px) {
			flex: 0 0 500px;
			margin-right: 20px;
		}
		@media (max-width: 992px) {
			flex: none;
			margin-right: 0;
			margin-bottom: 30px;
		}
	}

	&__info {
		flex: 1 1 auto;
	}

	&__text {
		margin-bottom: 18px;
	}

	&__buttons {
		margin: 0 -10px;
	}

	&__btn {
		margin: 10px;
	}

	&__territory {
		margin-bottom: 50px;
		@media (max-width: 992px) {
			margin-bottom: 30px;
		}
	}

	&__map {
		overflow: hidden;
		position: relative;
		height: 400px;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width: 767px) {
			height: 300px;
		}
		@media (max-width: 500px) {
			margin: 0 -20px;
		}
	}

	&__manager {
		margin-bottom: 50px;
		@media (max-width:992px) {
			margin-bottom: 30px;
		}
	}

	&__wrap {
		display: flex;
		@media (max-width:500px) {
			flex-direction: column;
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		width: 145px;
		height: 145px;
		margin-right: 40px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width:500px) {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	&__contact {
		display: flex;
		flex-direction: column;
	}

	&__name {
		font-weight: 300;
		font-size: 24px;
		line-height: 130%;
		margin-bottom: 12px;
	}

	&__mail {
		font-weight: normal;
		font-size: 19px;
		line-height: 130%;
		color: var(--cyan);
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		span {
			display: inline-block;
			padding-left: 12px;
			text-decoration: underline;
			font-size: 15px;
		}
		@media (any-hover: hover) {
			&:hover {
				span {
					text-decoration: none;
				}
			}
		}
	}

	&__phone {
		color: var(--cyan);
		font-size: 22px;
		display: flex;
		align-items: center;
		line-height: 130%;
		margin-bottom: 6px;
		span {
			font-weight: 400;
			font-size: 15px;
			line-height: 130%;
			color: var(--black);
			display: inline-block;
			padding-left: 12px;
		}
	}

	&__plan {
		margin-bottom: 50px;
		@media (max-width:992px) {
			margin-bottom: 30px;
		}
	}
}
.slider {
	margin-bottom: 22px;
	@media (max-width: 500px) {
		margin-bottom: 15px;
	}

	&__image {
		overflow: hidden;
		position: relative;
		padding: 0 0 51% 0;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}
.slider-small {
	&__slide {
		transition: opacity 0.3s ease 0s;
		opacity: 0.5;
		cursor: pointer;
		&.swiper-slide-thumb-active {
			opacity: 1;
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		width: 100px;
		padding: 0px 0px 100% 0px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width: 1024px) {
			width: 90px;
		}
		@media (max-width: 992px) {
			width: 100%;
		}
	}
}
.swiper-button-prev {
	height: 36px;
	width: 36px;
	border-radius: 50%;
	background: var(--cyan);
	color: #fff;
	transition: all 0.3s ease 0s;
	&::after {
		display: none;
	}
}
.swiper-button-next {
	height: 36px;
	width: 36px;
	border-radius: 50%;
	background: var(--cyan);
	color: #fff;
	transition: all 0.3s ease 0s;
	&::after {
		display: none;
	}
}
.interface {
	border: 1px solid #DDE2F0;
	padding: 30px;
	&__controls{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 0 -6px;
	}

	&__zoom {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: var(--cyan);
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		cursor: pointer;
		margin: 0 6px;
	}

	&__legend {
		display: flex;
		align-items: center;
		&:not(:last-child){
			margin-bottom: 12px;
		}
	}

	&__marker {
		width: 23px;
		height: 23px;
		border-radius: 50%;
		margin-right: 12px;
	}

	&__marker--yellow {
		background: #FAFF71;
	}

	&__marker--red {
		background: #FF3535;
	}

	&__image {
		margin-bottom: 42px;
		img {
			max-width: 100%;
		}
	}
}
