.short {
	position: relative;
	@media (max-width:1024px) {
		padding: 50px 0;
	}
	@media (max-width:992px) {
		background: url('../images/watermark.svg') 50% 70% / contain no-repeat;
	}
	@media (max-width:500px) {
		padding: 30px 0;
	}

	&__top {
		margin-bottom: 150px;
		@media (max-width:1200px) {
			margin-bottom: 60px;
		}
		@media (max-width:767px) {
			margin-bottom: 30px;
		}
	}

	&__subtitle {
		margin-bottom: 26px;
	}

	&__text {
		font-weight: 500;
		max-width: 670px;
	}

	&__bottom {
		display: flex;
		align-items: flex-end;
		@media (max-width:1200px) {
			flex-direction: column;
		}	
	}

	&__info {
		flex: 0 0 50%;
	}

	&__descripton {
		padding-bottom: 40px;
		margin-bottom: 12px;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			background: var(--cyan);
			height: 2px;
			width: 80px;
		}
		p {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}

	&__name {
		font-weight: 500;
		font-size: 20px;
		line-height: 180%;
	}

	&__position {
		font-weight: 400;
		font-size: 14px;
		line-height: 180%;
	}

	&__watermark {
		width: 1336px;
		position: absolute;
		z-index: -1;
		left: 10.5%;
		top: 30%;
		pointer-events: none;
		transition: all 1s ease 0s;
		img {
			max-width: 100%;
		}
		@media (max-width:1600px) {
			width: 1000px;
			top: 20%;
		}
		@media (max-width:1365px) {
			width: 800px;
			top: 30%;
		}
		@media (max-width:1200px) {
			top: 50%;
		}
		@media (max-width:992px) {
			display: none;
		}
	}
}
.infographic {
	padding: 100px 80px 0px 0px;
	margin: 0 -15px;
	flex: 0 0 50%;
	display: flex;
	flex-wrap: wrap;
	@media (max-width:1200px) {
		padding: 0px 0px 20px 0px;
		margin: 0;
		width: 100%;
	}
	@media (max-width:500px) {
		padding: 0;
	}
	&__item {
		flex: 0 0 50%;
		padding: 15px;
		@media (max-width:1200px) {
			flex: 0 0 25%;
		}
		@media (max-width:992px) {
			flex: 0 0 50%;
			display: flex;
			flex-direction: column;
		}
		@media (max-width:500px) {
			padding: 0px 0px 30px 0px;
			flex: 0 0 100%;
		}
		&:nth-of-type(1) {
			background: url("../images/building.svg") 15px 5px no-repeat;
		}
		&:nth-of-type(2) {
			background: url("../images/surface.svg") 15px 5px no-repeat;
		}
		&:nth-of-type(3) {
			background: url("../images/badge.svg") 15px 5px no-repeat;
		}
		&:nth-of-type(4) {
			background: url("../images/like.svg") 15px 5px no-repeat;
		}
	}

	&__number {
		font-weight: 500;
		font-size: 44px;
		line-height: 180%;
		padding-left: 40px;
		color: var(--cyan);
		white-space: nowrap;
		span {
			font-size: 24px;
		}
	}

	&__text {
		font-weight: 400;
		font-size: 15px;
		line-height: 180%;
		color: var(--black);
	}
}
