.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	overflow-y: auto;
	overflow-x: hidden;
	transition: all 0.5s ease 0s;
	z-index: 100;
	&#popup-thx{
		.popup__text{
			margin-bottom: 0;
		}
	}
	&#popup-spinner{
		background: rgba(255, 255, 255, 0.8);
	}
	&._open{
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		.popup__content{
			transform: translate(0, 0) perspective(1000px) rotateY(0deg);
		}
	}
	&__body {
		min-height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px 20px;
	}

	&__content {
		background: #ffffff;
		padding: 50px;
		max-width: 820px;
		position: relative;
		transition: all 0.5s ease 0s;
		transform: translate(100%,0) perspective(1000px) rotateY(-90deg);
		@media (max-width:767px) {
			padding: 50px 20px;
		}
	}

	&__close {
		font-size: 14px;
		position: absolute;
		top: 0;
		right: 0;
		color: #c4c7d0;
		transform: rotate(0);
		transition: all 0.3s ease 0s;
		display: block;
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			color: var(--black);
			transform: rotate3d(0, 0, 1, -90deg);
		}
	}

	&__heading {
		margin-bottom: 12px;
	}

	&__text {
		font-weight: 300;
		font-size: 16px;
		line-height: 130%;
		text-align: center;
		margin-bottom: 24px;
	}
}
.form {
	&__inputs {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px;
	}

	&__label {
		font-weight: 500;
		flex: 0 0 50%;
		display: flex;
		flex-direction: column;
		padding: 12px;
		span {
			display: block;
			margin-bottom: 6px;
		}
		&.form__label--textarea {
			flex: 0 0 100%;
		}
		@media (max-width:767px) {
			flex: 0 0 100%;
		}
	}

	&__input {
		background: #f2f5fd;
		border: 1px solid #dde2f0;
		padding: 16px 20px;
		font-size: 16px;
		color: var(--black);
		transition: border-color 0.3s ease 0s;
		&::placeholder {
			font-weight: normal;
			font-size: 16px;
			line-height: 130%;
			color: #b9bdd4;
			transition: all 0.3s ease 0s;
			opacity: 1;
		}
		&:focus {
			border: 1px solid var(--black);
			&::placeholder {
				opacity: 0;
			}
		}
		@media (any-hover: hover) {
			&:hover {
				border: 1px solid var(--black);
			}
		}
		&._error{
			border: 1px solid var(--error);
			&::placeholder{
				color: var(--error);
			}
		}
	}

	textarea.form__input {
		resize: vertical;
		overflow: hidden;
		min-height: 120px;
		transition: all 0.3s ease 0s;
	}

	&__alert{
		font-weight: 400;
		text-align: center;
		font-size: 14px;
		line-height: 130%;
		color: var(--error);
		margin-bottom: 12px;
		transition: all 0.3s ease 0s;
		opacity: 0;
		visibility: hidden;
		&._error{
			opacity: 1;
			visibility: visible;
		}
	}

	&__bottom {
		display: flex;
		@media (max-width:767px) {
			flex-direction: column;
		}
	}

	&__left {
		flex: 1 1 auto;
		padding-right: 20px;
		@media (max-width:767px) {
			padding-right: 0;
			margin-bottom: 20px;
		}
	}

	&__checkbox {
		margin-bottom: 18px;
	}

	&__note {
		font-weight: normal;
		font-size: 12px;
		line-height: 130%;
	}

	&__right {
		flex: 0 0 33.333%;
		@media (max-width:767px) {
			flex: none;
		}
	}

	&__btn {
		width: 100%;
	}
}
.checkbox {
	display: flex;
	&__input {
		display: none;
		&:checked + .checkbox__label{
			&:after{
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__label {
		font-weight: normal;
		font-size: 12px;
		line-height: 130%;
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		flex: 1 1 auto;
		position: relative;
		transition: color 0.3s ease 0s;
		a {
			color: inherit;
			text-decoration: underline;
			@media (any-hover: hover) {
				&:hover {
					text-decoration: none;
				}
			}
		}
		&:before {
			content: '';
			align-self: flex-start;
			flex: 0 0 24px;
			height: 24px;
			background: #f2f5fd;
			border: 1px solid #dde2f0;
			margin-right: 10px;
			transition: border-color 0.3s ease 0s;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 24px;
			height: 24px;
			background: url('../images/tick.svg') center/ 16px auto no-repeat var(--cyan);
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease 0s;
		}
		&:hover,
		&:focus{
			&:before{
				border: 1px solid var(--black);
			}
		}
		&._error{
			&:before{
				border: 1px solid var(--error);
			}
			color: var(--error);
		}
	}
}
