.tabs {
	&__triggers {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		padding: 60px 0 50px 0;
		@media (max-width: 1200px) {
			padding: 30px 0;
		}
	}

	&__trigger {
		font-size: 17px;
		line-height: 130%;
		color: var(--black);
		font-weight: 500;
		margin: 0 15px;
		padding: 6px 0 16px 0;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 0;
			height: 2px;
			width: 100%;
			background: var(--cyan);
			transform: scaleX(0);
			transition: transform 0.3s ease 0s;
			transform-origin: right;
		}
		@media (any-hover: hover) {
			&:hover {
				&::after {
					transform: scaleX(1);
					transform-origin: left;
				}
			}
		}
		&.tabs__trigger--active {
			&::after {
				transform: scaleX(1);
				transform-origin: left;
			}
		}
		@media (max-width: 992px) {
			padding: 6px 0;
		}
		@media (max-width: 360px) {
			width: 100%;
		}
	}

	&__item {
		//display: none;
		&.tabs__item--active {
			display: block;
		}
	}
}
.item-tab {
	&__unit {
		display: flex;
		margin-bottom: 55px;
		@media (max-width: 600px) {
			flex-direction: column;
			margin-bottom: 30px;
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		flex: 0 0 200px;
		width: 200px;
		height: 200px;
		margin-right: 40px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width: 767px) {
			margin-right: 20px;
		}
		@media (max-width: 600px) {
			margin-right: 0;
			margin-bottom: 20px;
		}
		@media (max-width: 425px) {
			margin-bottom: 10px;
		}
	}

	&__info {
		flex: 1 1 auto;
	}

	&__head {
		padding-bottom: 18px;
		margin-bottom: 24px;
		border-bottom: 1px solid #e1e6f3;
	}

	&__name {
		font-weight: 300;
		font-size: 32px;
		line-height: 130%;
		margin-bottom: 8px;
		@media (max-width: 767px) {
			font-size: 26px;
		}
	}

	&__mail {
		font-weight: normal;
		font-size: 15px;
		line-height: 130%;
		color: var(--cyan);
		display: flex;
		align-items: center;
		span {
			display: inline-block;
			text-decoration: underline;
			padding-left: 12px;
		}
		@media (any-hover: hover) {
			&:hover {
				span {
					text-decoration: none;
				}
			}
		}
	}

	&__caption {
		font-weight: 500;
		font-size: 17px;
		line-height: 130%;
		margin-bottom: 24px;
	}

	&__item {
		margin-bottom: 24px;
		margin-left: 18px;
		padding-left: 12px;
		&::marker {
			font-size: 15px;
			line-height: 130%;
			color: var(--cyan);
			font-weight: 700;
		}
	}

	&__link {
		font-size: 15px;
		line-height: 130%;
		color: var(--black);
		font-weight: 500;
		margin-bottom: 4px;
		padding-bottom: 4px;
		display: inline-block;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			background: var(--cyan);
			height: 2px;
			width: 100%;
			transform: scaleX(0);
			transition: transform 0.3s ease 0s;
			transform-origin: right;
		}
		@media (any-hover: hover) {
			&:hover {
				&::before {
					transform: scaleX(1);
					transform-origin: left;
				}
			}
		}
	}

	&__address {
		font-weight: normal;
		font-size: 13px;
		line-height: 130%;
		color: #a0a4bc;
	}
}
.filter {
	background: #f2f5fd;
	padding: 30px 50px;
	margin: 0 0 65px 0;
	@media (max-width: 1200px) {
		margin: 0 -20px 300px -20px;
	}
	@media (max-width: 767px) {
		padding: 30px 20px;
	}
	&__inputs {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 -10px 40px -10px;
		& > * {
			padding: 0 10px;
		}
	}

	&__label {
		flex: 1 1 auto !important;
		font-weight: 500 !important;
		font-size: 14px !important;
		line-height: 130% !important;
		margin-top: 10px !important;
		span {
			display: inline-block !important;
			margin-bottom: 5px !important;
		}
	}

	&__select-wrap {
		min-width: 250px !important;
		position: relative !important;
		span {
			position: absolute !important;
			top: 20px !important;
			right: 16px !important;
			font-size: 8px !important;
			pointer-events: none !important;
		}
	}

	&__select {
		font-size: 14px !important;
		appearance: none !important;
		background: #ffffff !important;
		border: 1px solid #dde2f0 !important;
		padding: 16px 40px 16px 20px !important;
		height: 100% !important;
		width: 100% !important;
		transition: border-color 0.3s ease 0s !important;
		cursor: pointer !important;
		&:hover,
		&:focus {
			border: 1px solid var(--black);
		}
	}

	&__buttons {
		display: flex !important;
		justify-content: space-between !important;
		flex-wrap: wrap !important;
		margin: -5px !important;
		@media (max-width:992px) {
			select {
				font-size: 14px !important;
				appearance: none !important;
				background: #ffffff !important;
				border: 1px solid #dde2f0 !important;
				padding: 16px 40px 16px 20px !important;
				height: 100% !important;
				width: 100% !important;
				transition: border-color 0.3s ease 0s !important;
				cursor: pointer !important;
				&:hover,
				&:focus {
					border: 1px solid var(--black);
				}
				option {
					color: #2e3350 !important;
					background: transparent !important;
					border: 0 !important;
					padding: 0 !important;
					text-align: left !important;
					margin: 0 !important;
				}
			}
		}
	}

	&__check-btn {
		font-size: 14px !important;
		background: #ffffff !important;
		border: 1px solid #dde2f0 !important;
		padding: 16px !important;
		text-align: center !important;
		transition: all 0.3s ease 0s !important;
		margin: 5px !important;
		&.filter__check-btn--active {
			background: var(--orange) !important;
			color: #fff !important;
			border: 1px solid var(--orange) !important;
			&:hover {
				border: 1px solid var(--orange);
			}
		}
		@media (any-hover: hover) {
			&:hover {
				border: 1px solid var(--black);
			}
		}
	}

	&__bottom {
		display: flex !important;
		align-items: center !important;
		justify-content: space-between !important;
		@media (max-width: 767px) {
			flex-direction: column !important;
			align-items: stretch !important;
		}
	}

	&__checkbox {
		label {
			font-weight: 500 !important;
			font-size: 14px !important;
			line-height: 130% !important;
			&:before {
				background: #ffffff !important;
			}
		}
	}

	&__print {
		color: var(--cyan) !important;
		font-size: 20px !important;
		display: flex !important;
		align-items: center !important;
		align-self: center !important;
		margin: 10px !important;
		span {
			font-weight: 500 !important;
			font-size: 11px !important;
			line-height: 130% !important;
			letter-spacing: 0.1em !important;
			text-decoration-line: underline !important;
			text-transform: uppercase !important;
			color: var(--black) !important;
			display: inline-block !important;
			padding-left: 8px !important;
		}
		@media (any-hover: hover) {
			&:hover {
				span {
					text-decoration: none;
				}
			}
		}
		@media (max-width: 767px) {
			align-self: flex-start !important;
			margin: 20px 0 !important;
		}
	}

	&__reset {
		margin: 10px 10px 10px 0 !important;
		font-weight: 500 !important;
		font-size: 11px !important;
		line-height: 130% !important;
		text-align: center !important;
		letter-spacing: 0.1em !important;
		text-decoration-line: underline !important;
		text-transform: uppercase !important;
		color: #b9bdd4 !important;
		transition: color 0.3s ease 0s !important;
		&:hover {
			color: var(--black);
		}
		@media (max-width: 767px) {
			margin: 20px 0 !important;
		}
	}

	&__check-group {
		margin-bottom: 20px !important;
	}

	&__group-label {
		margin-bottom: 5px !important;
		font-weight: 500 !important;
		font-size: 14px !important;
		line-height: 130% !important;
	}

	&__boxes {
		display: flex !important;
		flex-wrap: wrap !important;
		margin: 0 0 0 -20px !important;
		.checkbox {
			flex: 1 1 auto !important;
			padding: 10px 20px !important;
		}
		.checkbox__label {
			align-items: flex-start !important;
		}
	}
}
[class*="copyrights-pane"] {
	display: none !important;
}
.balloon-wrapper {
	overflow-y: auto;
	overflow-x: hidden;
}
.balloon-title {
	color: var(--black);
	transition: color 0.3s ease 0s;
	text-decoration: underline;
	font-weight: 700;
	font-size: 15px;
	@media (any-hover: hover) {
		&:hover {
			color: var(--cyan);
		}
	}
}
.balloon-title-wrap {
	margin-bottom: 4px;
}
.balloon-footer {
	color: #777;
}
.map {
	height: 680px;
	@media (max-width: 1200px) {
		height: 500px;
	}
	@media (max-width: 992px) {
		height: 400px;
	}
	@media (max-width: 767px) {
		height: 300px;
	}
	iframe {
		height: 100%;
		width: 100%;
		display: block;
	}

	&__body {
		position: relative;
		max-width: 1140px;
		margin: 0 auto;
	}

	&__panel {
		padding: 40px 20px 30px 20px;
		position: absolute;
		top: 80px;
		left: 0;
		z-index: 1;
		background: #fff;
		width: 360px;
		height: 520px;
		filter: drop-shadow(5px 15px 40px rgba(30, 35, 66, 0.15));
		@media (max-width: 1200px) {
			width: 100%;
			height: 300px;
			top: -300px;
			padding: 20px;
			height: 300px;
		}
	}

	&__title {
		font-weight: 300;
		font-size: 32px;
		line-height: 130%;
		color: var(--black);
		margin-bottom: 20px;
		padding-left: 18px;
		@media (max-width: 1200px) {
			font-size: 26px;
			margin-bottom: 4px;
		}
		@media (max-width: 767px) {
			padding-left: 0;
		}
	}

	&__list {
		padding-right: 10px;
		overflow-x: hidden;
		overflow-y: auto;
		height: 360px;
		scrollbar-width: thin;
		scrollbar-color: var(--cyan) #e1e6f3;
		@media (max-width: 1200px) {
			height: 200px;
		}
		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}
		&::-webkit-scrollbar-track {
			background: #e1e6f3;
		}
		&::-webkit-scrollbar-thumb {
			background: var(--cyan);
		}
	}

	&__item {
		margin-bottom: 8px;
	}

	&__link {
		display: flex;
		padding: 14px 18px;
		transition: background-color 0.3s ease 0s;
		cursor: pointer;
		&:hover {
			background-color: #f2f5fd;
		}
		@media (max-width: 767px) {
			padding: 14px 18px 14px 0px;
		}
	}

	&__number {
		font-weight: normal;
		font-size: 15px;
		line-height: 130%;
		color: var(--cyan);
		margin-right: 20px;
		width: 20px;
		pointer-events: none;
	}

	&__info {
		pointer-events: none;
	}

	&__heading {
		font-size: 13px;
		line-height: 130%;
		color: #a0a4bc;
		pointer-events: none;
	}

	&__address {
		font-weight: 500;
		font-size: 15px;
		line-height: 130%;
		color: var(--black);
		margin-bottom: 4px;
		pointer-events: none;
	}
}
