.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	padding-top: 50px;
	padding-bottom: 10px;
	transition: transform 0.5s ease 0s, padding-top 0.5s ease 0s, background-color 0.5s ease 0s;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		//background: url("../images/footer-bg.webp") center/cover no-repeat;
		transition: all 0.3s ease 0s;
		opacity: 0;
	}
	&._hide {
		transform: translateY(-100%);
	}
	&._paint {
		padding-top: 10px;
		background: var(--grey);
		&:before {
			opacity: 0.15;
		}
	}
	@media (max-width: 1024px) {
		padding-top: 10px;
	}
	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__phone {
		font-weight: 400;
		font-size: 20px;
		line-height: 130%;
		color: var(--black);
		margin: 0 20px;
		position: relative;
		z-index: 5;
		white-space: nowrap;
		span {
			&:first-child {
				display: none;
			}
		}
		&:before {
			content: "";
			height: 2px;
			width: 100%;
			transform: scaleX(0);
			transition: transform 0.3s ease 0s;
			transform-origin: left;
			position: absolute;
			bottom: -3px;
			left: 0;
			background: #f26657;
		}
		@media (any-hover: hover) {
			&:hover {
				&:before {
					transform: scaleX(1);
					transform-origin: right;
				}
			}
		}
		@media (max-width: 767px) {
			font-size: 30px;
			span {
				&:first-child {
					display: block;
				}
				&:last-child {
					display: none;
				}
			}
		}
	}

	&__btn {
		position: relative;
		z-index: 5;
		white-space: nowrap;
		@media (max-width: 500px) {
			display: none;
		}
	}

	&__burger {
		display: none;
		@media (max-width: 1024px) {
			margin-left: 20px;
			display: block;
			z-index: 5;
			position: relative;
			width: 30px;
			height: 18px;
			transition: all 0.3s ease 0s;
			&:before,
			&:after,
			span {
				position: absolute;
				left: 0;
				height: 2px;
				width: 100%;
				transition: all 0.3s ease 0s;
				background: var(--black);
			}
			&:before,
			&:after {
				content: "";
			}
			&:before {
				top: 0;
			}
			&:after {
				bottom: 0;
			}
			span {
				top: 50%;
				transform: scale(1) translate(0, -50%);
			}
			&._active {
				&:before {
					top: 50%;
					transform: translate(0, -50%) rotate(-45deg);
				}
				&:after {
					bottom: 50%;
					transform: translate(0, 50%) rotate(45deg);
				}
				span {
					transform: scale(0);
				}
			}
		}
	}
}
.logo {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 5;
	flex: 0 0 200px;
	@media (max-width: 1024px) {
		flex: none;
	}
	&__text {
		width: 140px;
		position: relative;
		top: 13px;
		img {
			max-width: 100%;
		}
		@media (max-width: 1024px) {
			width: 80px;
			top: 8px;
		}
	}
	&__image {
		width: 42px;
		margin-right: 18px;
		img {
			max-width: 100%;
		}
		@media (max-width: 1024px) {
			width: 20px;
			margin-right: 10px;
		}
	}
}
.menu {
	flex: 1 1 auto;
	margin: 0 20px;
	&__body {
		@media (max-width: 1024px) {
			position: fixed;
			z-index: 2;
			top: 0;
			right: -100%;
			width: 100%;
			height: 100%;
			background: #fff;
			padding: 100px 20px 40px 20px;
			transition: right 0.3s ease 0s;
			overflow: auto;
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				//background: url("../images/footer-bg.webp") center/cover no-repeat;
				opacity: 0.15;
				z-index: -1;
			}
			&:before {
				content: "";
				position: fixed;
				z-index: 6;
				top: 0;
				left: 0;
				width: 100%;
				height: 72px;
				background: #fff;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s ease 0s;
				@media (max-width: 500px) {
					height: 60px;
				}
			}
			&._active {
				right: 0;
				&:before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	&__list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		& > li {
			padding: 10px 0;
			position: relative;
			margin: 0px 0px 0px 20px;
			display: flex;
			align-items: center;
			@media (min-width: 1025px) {
				&:hover {
					.menu__link {
						&:before {
							transform: scaleX(1);
							transform-origin: right;
						}
					}
					.menu__sub-list {
						opacity: 1;
						visibility: visible;
						pointer-events: all;
						transform: translate(0, 0);
					}
					.menu__arrow {
						transform: translate(0, 1px) rotate(90deg);
					}
				}
			}
			@media (max-width: 1024px) {
				margin: 0px 0px 30px 0px;
				flex-wrap: wrap;
				width: 100%;
				&:last-child {
					margin: 0;
				}
				&._active {
					.menu__link {
						&:before {
							transform: scaleX(1);
							transform-origin: right;
						}
					}
					.menu__sub-list {
						opacity: 1;
						visibility: visible;
						pointer-events: all;
						transform: translate(0, 0);
						display: block;
					}
					.menu__arrow {
						transform: translate(0, 1px) rotate(90deg);
					}
				}
			}
		}
	}

	&__link {
		flex: 1 1 auto;
		font-size: 14px;
		line-height: 130%;
		font-weight: 400;
		color: var(--black);
		position: relative;
		z-index: 0;
		&:before {
			content: "";
			height: 2px;
			width: 100%;
			transform: scaleX(0);
			transition: transform 0.3s ease 0s;
			transform-origin: left;
			position: absolute;
			bottom: -3px;
			left: 0;
			background: #f26657;
		}
		@media (max-width: 1024px) {
			font-size: 24px;
			&:before {
				transform-origin: left;
			}
		}
	}

	&__arrow {
		transition: transform 0.3s ease 0s;
		font-size: 6px;
		color: var(--black);
		margin-left: 6px;
		transform: translate(0, 1px) rotate(0);
		@media (max-width: 1024px) {
			font-size: 14px;
		}
	}

	&__sub-list {
		position: absolute;
		z-index: 5;
		top: 100%;
		right: 0;
		padding: 10px 0;
		background: #fff;
		min-width: 200px;
		transform: translate(0, 10%);
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: all 0.3s ease 0s;
		li {
			padding: 0 15px;
			position: relative;
			z-index: 11;
			transition: background-color 0.3s ease 0s;
			&:last-child {
				margin: 0;
			}
			@media (any-hover: hover) {
				&:hover {
					background: var(--grey);
					.menu__sub-link{
						color: #f26657;
					}
				}
			}
		}
		@media (max-width: 1024px) {
			position: relative;
			flex: 1 1 100%;
			margin: 20px 0px 0px 0px;
			display: none;
		}
	}

	&__sub-link {
		color: #2e3350;
		font-size: 14px;
		transition: color 0.3s ease 0s;
		display: block;
		font-weight: 500;
		padding: 10px 0;
		@media (max-width: 1024px) {
			font-size: 20px;
		}
	}

	&__request {
		display: none;
		@media (max-width: 500px) {
			margin-top: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}
}
