.invest {
	@media (max-width:1024px) {
		padding: 50px 0;
	}
	@media (max-width:500px) {
		padding: 30px 0;
	}

	&__body {
		display: flex;
		align-items: center;
		@media (max-width:767px) {
			flex-direction: column;
		}
	}

	&__image {
		flex: 0 0 500px;
		margin-right: 32px;
		img {
			max-width: 100%;
		}
		@media (max-width:992px) {
			flex: 0 0 300px;
		}
		@media (max-width:767px) {
			flex: none;
			margin-right: 0;
			margin-bottom: 20px;
			max-width: 300px;
		}
	}

	&__info {
		flex: 1 1 auto;
	}

	&__caption {
		font-weight: 300;
		font-size: 38px;
		line-height: 130%;
		margin-bottom: 24px;
		@media (max-width:992px) {
			font-size: 30px;
		}
		@media (max-width:500px) {
			font-size: 26px;
			margin-bottom: 12px;
		}
	}

	&__text {
		font-weight: 400;
		font-size: 18px;
		line-height: 170%;
		margin-bottom: 40px;
		@media (max-width:500px) {
			margin-bottom: 20px;
		}
	}

	&__btn {
		@media (max-width:500px) {
			width: 100%;
		}
	}
}
