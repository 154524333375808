.news {
	padding: 30px 0 50px 0;
	@media (max-width:992px) {
		padding: 30px 0 20px 0;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		@media (max-width:992px) {
			margin: 0 -10px;
		}
	}

	&__item {
		flex: 0 0 33.333%;
		padding: 30px 15px;
		display: flex;
		flex-direction: column;
		@media (max-width:992px) {
			padding: 20px 10px;
			flex: 0 0 50%;
		}
		@media (max-width:600px) {
			flex: 0 0 100%;
		}
	}

	&__image {
		display: block;
		overflow: hidden;
		position: relative;
		padding: 0 0 61% 0;
		filter: drop-shadow(5px 15px 40px rgba(30, 35, 66, 0.15));
		margin-bottom: 24px;
		transition: filter 0.5s ease 0s;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
			transition: transform 0.5s ease 0s;
			transform: scale3d(1, 1, 1);
		}
		@media (any-hover: hover) {
			&:hover {
				filter: drop-shadow(5px 15px 80px rgba(30, 35, 66, 0));
				img{
					transform: scale3d(1.03, 1.03, 1.03);
				}
			}
		}
	}

	&__time {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		@media (max-width:767px) {
			margin-bottom: 12px;
		}
	}

	&__icon {
		width: 32px;
		height: 32px;
		flex: 0 0 32px;
		background: var(--cyan);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		color: #fff;
		font-size: 18px;
		margin-right: 12px;
	}

	&__date {
		font-size: 14px;
		line-height: 130%;
	}

	&__title {
		font-size: 18px;
		line-height: 130%;
		font-weight: 500;
		margin-bottom: 24px;
		@media (max-width:767px) {
			margin-bottom: 12px;
		}
	}

	&__text {
		font-size: 13px;
		line-height: 170%;
		margin-bottom: 24px;
		flex: 1 1 auto;
		@media (max-width:767px) {
			margin-bottom: 12px;
		}
	}

	&__btn {
		color: var(--black);
		align-self: flex-start;
		@media (max-width:600px) {
			align-self: stretch;
		}
	}
}
