.scroll-top {
	position: fixed;
	bottom: 100px;
	left: 0;
	width: 100%;
	pointer-events: none;
	&__body {
		max-width: 1250px;
		margin: 0 auto;
		display: flex;
		justify-content: flex-end;
		@media (max-width: 1365px) {
			max-width: 1180px;
			padding: 0 20px;
		}
	}
	&__button {
		font-size: 20px;
		height: 55px;
		width: 55px;
		border-radius: 50%;
		background: var(--cyan);
		color: #ffffff;
		transition: all 0.3s ease 0s;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-content: center;
		&._show {
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
		@media (any-hover: hover) {
			&:hover {
				background: var(--orange);
			}
		}
	}
}
