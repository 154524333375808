@font-face {
	font-family: "icons";
	src: url("../fonts/icons.ttf?dp2rx4") format("truetype"), url("../fonts/icons.woff?dp2rx4") format("woff"),
		url("../fonts/icons.svg?dp2rx4#icons") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icons-"]::before,
[class*=" icons-"]::before {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icons" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icons-play:before {
	content: "\e900";
}
.icons-calendar:before {
	content: "\e901";
}
.icons-zoom-in:before {
	content: "\e902";
}
.icons-zoom-out:before {
	content: "\e903";
}
.icons-phone:before {
	content: "\e904";
}
.icons-star:before {
	content: "\e905";
}
.icons-slider-arrow-next:before {
	content: "\e906";
}
.icons-slider-arrow-prev:before {
	content: "\e907";
}
.icons-pin:before {
	content: "\e908";
}
.icons-mail:before {
	content: "\e909";
}
.icons-checkbox-tick:before {
	content: "\e90a";
}
.icons-house:before {
	content: "\e90b";
}
.icons-printer:before {
	content: "\e90c";
}
.icons-arrow-scroll-top:before {
	content: "\e90d";
}
.icons-tick:before {
	content: "\e90e";
}
.icons-small-pin:before {
	content: "\e90f";
}
.icons-arrow:before {
	content: "\e910";
}
.icons-cross:before {
	content: "\e911";
}
