.footer {
	position: relative;
	padding: 75px 0;
	background: var(--grey);
	clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 100%);
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		opacity: 0.15;
	}
	&.footer--index {
		padding: 175px 0px 75px 0px;
		clip-path: polygon(0 25%, 100% 0, 100% 100%, 0% 100%);
		@media (max-width:1024px) {
			clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 100%);
			padding: 40px 0;
		}
	}

	&__body {
		display: flex;
		font-weight: 300;
		font-size: 13px;
		line-height: 130%;
		color: #ffffff;
		a {
			color: inherit;
		}
		p{
			margin-bottom: 20px;
		}
		@media (max-width:767px) {
			flex-direction: column;
		}
	}

	&__contacts {
		flex: 0 0 25%;
		margin-right: 20px;
		a{
			font-weight: 500;
			&[href^="mailto:"]{
				font-weight: 300;
				text-decoration: underline;
			}
			@media (any-hover: hover) {
				&:hover {
					text-decoration: underline;
					&[href^="mailto:"]{
						text-decoration: none;
					}
				}
			}
		}
		@media (max-width:767px) {
			flex: none;
			margin-bottom: 20px;
			margin-right: 0;
			p{
				margin: 10px;
			}
		}
	}

	&__logo {
		margin-bottom: 60px;
		@media (max-width:767px) {
			margin-bottom: 20px;
		}
		@media (max-width:500px) {
			.logo__text{
				width: 140px;
			}
			.logo__image{
				width: 42px;
				margin-right: 18px;
			}
		}
	}

	&__paragraphs{
		color: var(--black);
		@media (max-width:767px) {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 -10px;
		}
	}

	&__row {
		display: grid;
		grid-template-columns: repeat(3, minmax(210px,1fr));
		gap: 20px;
		align-items: start;
		@media (max-width:1024px) {
			grid-template-columns: repeat(2, minmax(210px,1fr));
		}
		@media (max-width:500px) {
			grid-template-columns: 1fr;
		}
	}

	&__heading {
		margin-bottom: 16px;
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		color: var(--black) !important;
		display: block;
	}

	&__list {
		li {
			margin-bottom: 12px;
			a {
				text-decoration: underline;
				color: var(--black);
				@media (any-hover: hover) {
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}
