.partners {
	background: var(--grey);
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
	padding-bottom: 230px;
	@media (max-width: 1024px) {
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		padding: 50px 0 0 0;
	}
	@media (max-width: 500px) {
		padding: 30px 0 0 0;
	}

	&__subtitle {
		margin-bottom: 16px;
	}

	&__text {
		font-weight: 400;
		font-size: 18px;
		line-height: 170%;
		max-width: 550px;
	}

	&__slider {
		.slick-list {
			overflow: hidden;
			margin: 0 -20px;
		}
		.slick-track {
			display: flex;
			padding: 50px 0 0 0;
			@media (max-width:992px) {
				padding: 20px 0 30px 0;
			}
		}
	}

	&__image {
		display: flex !important;
		align-items: center;
		justify-content: center;
		text-align: center;
		max-width: 250px;
		opacity: 0.5;
		margin: 20px;
		height: 150px;
		filter: grayscale(1);
		transition: filter 0.3s ease 0s, opacity 0.3s ease 0s;
		img {
			max-width: 100%;
			max-height: 100%;
			display: inline-block;
		}
		&:hover {
			filter: grayscale(0);
			opacity: 1;
		}
		@media (max-width:992px) {
			height: 120px;
		}
	}
}
