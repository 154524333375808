.contacts {
	padding: 45px 0 80px 0;
	@media (max-width: 992px) {
		padding: 30px 0 40px 0;
	}
	@media (max-width:500px) {
		padding: 30px 0 0 0;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px 42px -15px;
		@media (max-width: 992px) {
			margin: 0 -15px 20px -15px;
		}
	}

	&__item {
		flex: 0 0 33.333%;
		display: flex;
		padding: 15px;
		@media (max-width: 992px) {
			flex: 0 0 100%;
		}
	}

	&__icon {
		flex: 0 0 60px;
		margin-right: 20px;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background: var(--cyan);
		color: #fff;
		font-size: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
		&.icons-mail {
			font-size: 20px;
		}
		@media (max-width: 500px) {
			flex: 0 0 50px;
			width: 50px;
			height: 50px;
			margin-right: 10px;
		}
	}

	&__heading {
		font-size: 18px;
		line-height: 170%;
		font-weight: 500;
		margin-bottom: 4px;
	}

	&__text {
		font-size: 15px;
		line-height: 170%;
	}

	&__phone {
		font-size: 15px;
		line-height: 170%;
		color: var(--black);
		@media (any-hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__mail {
		font-size: 15px;
		line-height: 170%;
		text-decoration: underline;
		color: var(--cyan);
	}

	&__map {
		overflow: hidden;
		position: relative;
		padding: 0 0 35% 0;
		min-height: 250px;
		margin-bottom: 60px;
		@media (max-width: 992px) {
			margin-bottom: 40px;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__caption {
		@media (max-width: 992px) {
			margin-bottom: 10px;
		}
	}
	&__form {
		background: #f2f5fd;
		padding: 40px;
		@media (max-width:767px) {
			padding: 40px 20px;
		}
		@media (max-width:500px) {
			margin: 0 -20px;
		}
		.form__label{
			&:nth-of-type(1),
			&:nth-of-type(2),
			&:nth-of-type(3){
				flex: 0 0 33.333%;
			}
			&:nth-of-type(4){
				flex: 0 0 100%;
			}
			@media (max-width:992px) {
				&:nth-of-type(1),
			&:nth-of-type(2),
			&:nth-of-type(3){
				flex: 0 0 100%;
			}
			}
		}
		.form__input {
			background: #ffffff;
		}
		.form__caption {
			font-size: 24px;
			line-height: 130%;
			margin-bottom: 4px;
			font-weight: 500;
		}
		.form__text {
			font-weight: 300;
			font-size: 16px;
			line-height: 130%;
			margin-bottom: 20px;
		}
	}
}
.team {
	margin-bottom: 70px;
	@media (max-width: 992px) {
		margin-bottom: 30px;
	}
	
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
	}

	&__item {
		flex: 0 0 50%;
		display: flex;
		padding: 15px;
		@media (max-width: 992px) {
			flex: 0 0 100%;
		}
		@media (max-width: 500px) {
			flex-direction: column;
		}
	}

	&__image {
		flex: 0 0 200px;
		width: 200px;
		height: 200px;
		overflow: hidden;
		position: relative;
		margin-right: 30px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width: 992px) {
			margin-right: 20px;
		}
		@media (max-width: 500px) {
			margin-right: 0;
			margin-bottom: 10px;
			width: 100%;
			height: auto;
			padding: 0px 0px 100% 0px;
		}
	}

	&__department {
		font-weight: 300;
		font-size: 24px;
		line-height: 130%;
		margin-bottom: 10px;
	}

	&__name {
		font-size: 15px;
		line-height: 130%;
		font-weight: 500;
		margin-bottom: 4px;
	}

	&__position {
		margin-bottom: 20px;
		font-weight: 300;
		font-size: 15px;
		line-height: 130%;
	}

	&__mail {
		font-size: 19px;
		display: block;
		font-weight: normal;
		line-height: 130%;
		color: var(--cyan);
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		span {
			text-decoration: underline;
			display: inline-block;
			margin-left: 12px;
			font-size: 15px;
			color: var(--cyan);
		}
		@media (any-hover: hover) {
			&:hover {
				span {
					text-decoration: none;
				}
			}
		}
	}

	&__phone {
		font-size: 22px;
		display: block;
		line-height: 130%;
		display: flex;
		align-items: center;
		color: var(--cyan);
		margin-bottom: 4px;
		span {
			font-size: 15px;
			color: var(--black);
			display: inline-block;
			margin-left: 12px;
		}
		@media (any-hover: hover) {
			&:hover {
				span {
					text-decoration: underline;
				}
			}
		}
	}
}
