.mainscreen {
	position: relative;
	background: linear-gradient(103.98deg, rgba(255,255,255,0) 0%, rgba(0, 160, 220, 0.3) 58.81%),
		url("../images/mainscreen-bg.webp") center/cover no-repeat;

	&__polygon {
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 20%;
		background: #fff;
		clip-path: polygon(0 0%, 0% 100%, 100% 100%);
		@media (max-width:1024px) {
			display: none;
		}
	}

	&__body {
		min-height: 100vh;
		padding: 150px 0 250px 0;
		@media (max-width: 1024px) {
			padding: 100px 0;
		}
	}

	&__info {
		position: relative;
		z-index: 1;
		width: 60%;
		@media (max-width: 1365px) {
			width: 100%;
			padding: 30px;
			background: rgba(0, 0, 0, 0.7);
		}
		@media (max-width: 500px) {
			padding: 30px 20px;
		}
	}

	&__title {
		margin-bottom: 12px;
		color: var(--black);
		@media (max-width: 1365px){
			color: #fff;
		}
	}

	&__text {
		font-weight: normal;
		font-size: 26px;
		line-height: 130%;
		color: var(--black);
		margin-bottom: 46px;
		@media (max-width: 1365px){
			color: #fff;
		}
		@media (max-width: 992px) {
			font-size: 22px;
		}
		@media (max-width: 767px) {
			font-size: 20px;
		}
	}

	&__btn {
		@media (max-width: 500px) {
			width: 100%;
		}
	}

	&__decor {
		width: 460px;
		position: absolute;
		z-index: 0;
		right: 20%;
		top: 25%;
		pointer-events: none;
		img {
			opacity: 0.7;
			max-width: 100%;
			filter: drop-shadow(5px 15px 40px rgba(30, 35, 66, 0.15));
		}
		@media (max-width: 1365px) {
			width: 400px;
			right: 10%;
		}
		@media (max-width: 992px) {
			width: 300px;
			right: 3%;
		}
	}
}
