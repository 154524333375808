.advantages {
	background: linear-gradient(103.98deg, #2e3350 0%, rgba(0, 160, 220, 0.75) 58.81%, rgba(0, 160, 220, 0.72) 97.65%),
		url("../images/adv-bg.webp") center / cover no-repeat;
	padding: 200px 0;
	color: #ffffff;
	position: relative;
	@media (max-width:1024px) {
		padding: 50px 0;
	}
	&__top-polygon {
		height: 150px;
		clip-path: polygon(0 0, 100% 0, 100% 0%, 0% 100%);
		background: var(--grey);
		position: absolute;
		top: -1px;
		left: 0;
		width: 100%;
		@media (max-width:1024px) {
			display: none;
		}
	}

	&__subtitle {
		color: #ffffff;
		margin-bottom: 30px;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -16px;
	}

	&__item {
		flex: 0 0 20%;
		padding: 16px;
		min-width: 200px;
		@media (max-width:1024px) {
			flex: 0 0 33.333%;
		}
		@media (max-width:767px) {
			flex: 0 0 50%;
		}
		@media (max-width:425px) {
			flex: 0 0 100%;
		}
	}

	&__icon {
		width: 54px;
		height: 54px;
		border-radius: 50%;
		border: 1px solid #fff;
		font-size: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 14px;
		@media (max-width:992px) {
			margin-bottom: 6px;
		}
	}

	&__text {
		font-weight: 400;
		font-size: 16px;
		line-height: 130%;
		span{
			font-weight: 700;
		}
	}

	&__bottom-polygon {
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 150px;
		background: var(--grey);
		clip-path: polygon(0 0, 100% 100%, 100% 100%, 0% 100%);
		@media (max-width:1024px) {
			display: none;
		}
	}
}
