.content {
	padding: 60px 0 30px 0 !important;
	display: flow-root !important;
	@media (max-width:767px) {
		padding: 40px 0 20px 0 !important;
	}
	h1{
		font-size: 50px !important;
		line-height: 130% !important;
		font-weight: 500 !important;
		color: #2e3350 !important;
		margin-bottom: 42px !important;
		max-width: 800px !important;
		@media (max-width:767px) {
			font-size: 40px !important;
			margin-bottom: 30px !important;
		}
		@media (max-width:500px) {
			font-size: 32px !important;
			margin-bottom: 30px !important;
		}
	}
	h2{
		font-size: 40px !important;
		line-height: 130% !important;
		font-weight: 500 !important;
		color: #2e3350 !important;
		margin-bottom: 42px !important;
		max-width: 800px !important;
		@media (max-width:767px) {
			font-size: 30px !important;
			margin-bottom: 30px !important;
		}
		@media (max-width:500px) {
			font-size: 26px !important;
			margin-bottom: 30px !important;
		}
	}
	h3 {
		font-size: 32px !important;
		line-height: 130% !important;
		font-weight: 500 !important;
		color: #2e3350 !important;
		margin-bottom: 42px !important;
		max-width: 800px !important;
		@media (max-width:767px) {
			font-size: 26px !important;
			margin-bottom: 30px !important;
		}
		@media (max-width:500px) {
			font-size: 24px !important;
			margin-bottom: 30px !important;
		}
	}
	h4{
		font-size: 28px !important;
		line-height: 130% !important;
		font-weight: 500 !important;
		color: #2e3350 !important;
		margin-bottom: 30px !important;
		max-width: 800px !important;
		@media (max-width:767px) {
			font-size: 22px !important;
			margin-bottom: 30px !important;
		}
		@media (max-width:500px) {
			font-size: 20px !important;
			margin-bottom: 20px !important;
		}
	}
	h5{
		font-size: 24px !important;
		line-height: 130% !important;
		font-weight: 500 !important;
		color: #2e3350 !important;
		margin-bottom: 30px !important;
		max-width: 800px !important;
		@media (max-width:767px) {
			font-size: 18px !important;
			margin-bottom: 30px !important;
		}
		@media (max-width:500px) {
			font-size: 16px !important;
			margin-bottom: 20px !important;
		}
	}
	h6{
		font-size: 20px !important;
		line-height: 130% !important;
		font-weight: 500 !important;
		color: #2e3350 !important;
		margin-bottom: 30px !important;
		max-width: 800px !important;
		@media (max-width:767px) {
			font-size: 16px !important;
			margin-bottom: 30px !important;
		}
		@media (max-width:500px) {
			font-size: 16px !important;
			margin-bottom: 20px !important;
		}
	}
	p {
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 185% !important;
		color: #2e3350 !important;
		margin-bottom: 30px !important;
		@media (max-width:767px) {
			margin-bottom: 20px !important;
		}
	}
	ul{
		padding-left: 30px !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 185% !important;
		color: #2e3350 !important;
		margin-bottom: 30px !important;
		li {
			list-style-type: disc !important;
			margin-bottom: 10px !important;
		}
		@media (max-width:767px) {
			margin-bottom: 20px !important;
		}
	}
	ol{
		padding-left: 30px !important;
		font-weight: 400 !important;
		font-size: 16px !important;
		line-height: 185% !important;
		color: #2e3350 !important;
		margin-bottom: 30px !important;
		li {
			list-style-type: decimal !important;
			margin-bottom: 10px !important;
		}
		@media (max-width:767px) {
			margin-bottom: 20px !important;
		}
	}
	img{
		float: right !important;
		max-width: 550px !important;
		filter: drop-shadow(5px 15px 40px rgba(30, 35, 66, 0.15)) !important;
		margin: 0 0 30px 30px !important;
		@media (max-width:992px) {
			max-width: 400px !important;
			margin: 0 0 30px 20px !important;
		}
		@media (max-width:767px) {
			float: none !important;
			margin: 0 0 30px 0 !important;
			display: block !important;
			max-width: 100% !important;
		}
	}
	strong {
		font-weight: 700 !important;
	}
}
