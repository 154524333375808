@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamPro-Light.eot");
	src: url("../fonts/GothamPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Light.woff") format("woff"),
		url("../fonts/GothamPro-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamPro.eot");
	src: url("../fonts/GothamPro.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro.woff") format("woff"),
		url("../fonts/GothamPro.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamPro-Medium.eot");
	src: url("../fonts/GothamPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Medium.woff") format("woff"),
		url("../fonts/GothamPro-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamPro-Bold.eot");
	src: url("../fonts/GothamPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Bold.woff") format("woff"),
		url("../fonts/GothamPro-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}
