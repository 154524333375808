.photos {
	padding: 50px 0 0 0;

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		@media (max-width:767px) {
			margin: 0 -10px;
		}
	}

	&__item {
		flex: 0 0 33.333%;
		padding: 0 15px 45px 15px;
		@media (max-width:992px) {
			padding: 0 15px 30px 15px;
		}
		@media (max-width:767px) {
			flex: 0 0 50%;
			padding: 0 10px 30px 10px;
		}
		@media (max-width:500px) {
			flex: 0 0 100%;
		}
	}

	&__image {
		filter: drop-shadow(5px 15px 40px rgba(30, 35, 66, 0.15));
		overflow: hidden;
		position: relative;
		padding: 0 0 61% 0;
		margin-bottom: 30px;
		img {
			transition: transform 0.5s ease 0s;
			transform: scale3d(1, 1, 1);
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (any-hover: hover) {
			&:hover {
				img {
					transform: scale3d(1.03, 1.03, 1.03);
				}
				.photos__cover {
					opacity: 1;
					visibility: visible;
				}
			}
		}
		@media (max-width:992px) {
			margin-bottom: 10px;
		}
	}

	&__cover {
		transition: all 0.5s ease 0s;
		background: linear-gradient(103.98deg, #2e3350 0%, rgba(0, 160, 220, 0.3) 58.81%, rgba(0, 160, 220, 0) 97.65%);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		visibility: hidden;
		padding: 20px;
		@media (max-width:1024px) {
			opacity: 1;
			visibility: visible;
		}
	}

	&__title {
		display: block;
		font-weight: 500;
		font-size: 18px;
		line-height: 130%;
		text-align: center;
		color: var(--black);
		transition: color 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				color: var(--orange);
			}
		}
	}
}
