// Общее
@import "./includes/normalize";
@import "./includes/font";
@import "./includes/icons";

// Блоки
:root {
	// Цвета
	--cyan: #00a0dc;
	--orange: #f26657;
	--black: #2e3350;
	--blue: #355271;
	--error: #ff3333;
	--grey: #f2f5fd;
}
html{
	scroll-padding-top: 100px;
	scroll-behavior: smooth;
}
body {
	font-family: "Gotham Pro", sans-serif;
	font-size: 16px;
	color: var(--black);
	&.body--index {
		background: url("../images/body-bg.svg") center / cover no-repeat;
	}
	&._lock {
		overflow: hidden;
	}
	overflow-x: hidden;
	scrollbar-width: thin;
	scrollbar-color: var(--orange) #fff;
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #fff;
	}
	&::-webkit-scrollbar-thumb {
		background: var(--orange);
		@media (any-hover: hover) {
			&:hover {
				background: #c05245;
			}
		}
	}
}
.wrapper {
	display: flex;
	min-height: 100%;
	flex-direction: column;
}
.main {
	flex: 1 1 auto;
}
.container {
	max-width: 1180px;
	margin: 0 auto;
	padding: 0 20px;
}
.btn {
	display: inline-block;
	font-weight: 400;
	font-size: 11px;
	line-height: 130%;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #ffffff;
	padding: 18px 24px;
	transition: all 0.3s ease 0s;
	border: 1px solid transparent;
}
.btn--blue {
	background: var(--cyan);
	@media (any-hover: hover) {
		&:hover {
			background: var(--orange);
		}
	}
}
.btn--orange {
	background: var(--orange);
	@media (any-hover: hover) {
		&:hover {
			background: var(--cyan);
		}
	}
}
.btn-no-bg {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 16px 30px;
	font-weight: 500;
	font-size: 16px;
	line-height: 130%;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	transition: all 0.3s ease 0s;
	border: 1px solid transparent;
	color: var(--black);
	span{
		display: inline-block;
		font-size: 11px;
		padding-left: 8px;
		text-decoration: underline;
		transition: color 0.3s ease 0s;
	}
}
.btn-no-bg--blue {
	border: 1px solid #00A0DC;
	color: var(--cyan);
	span{
		color: var(--black);
	}
	@media (any-hover: hover) {
		&:hover {
			background: var(--cyan);
			color: #fff;
			span{
				color: #fff;
			}
		}
	}
}
.btn-no-bg--orange {
	border: 1px solid #F26657;
	color: var(--orange);
	span{
		color: var(--black);
	}
	@media (any-hover: hover) {
		&:hover {
			background: var(--orange);
			color: #fff;
			span{
				color: #fff;
			}
		}
	}
}
.btn-no-bg--white {
	font-size: 11px;
	border: 1px solid #fff;
	color: #fff;
	@media (any-hover: hover) {
		&:hover {
			border: 1px solid var(--orange);
			background: var(--orange);
		}
	}
}
.title {
	font-weight: 300;
	font-size: 80px;
	line-height: 130%;
	color: #ffffff;
	word-break: break-word;
	@media (max-width: 992px) {
		font-size: 60px;
	}
	@media (max-width: 767px) {
		font-size: 46px;
	}
	@media (max-width: 500px) {
		font-size: 36px;
	}
	@media (max-width: 425px) {
		font-size: 32px;
	}
}
.subtitle {
	font-weight: 300;
	font-size: 54px;
	line-height: 130%;
	color: var(--black);
	@media (max-width: 992px) {
		font-size: 40px;
	}
	@media (max-width: 767px) {
		font-size: 36px;
	}
	@media (max-width: 425px) {
		font-size: 28px;
	}
}
.heading {
	font-weight: 300;
	font-size: 34px;
	line-height: 130%;
	text-align: center;
	color: var(--black);
	@media (max-width: 992px) {
		font-size: 30px;
	}
	@media (max-width: 767px) {
		font-size: 26px;
	}
	@media (max-width: 425px) {
		font-size: 22px;
	}
}
.caption {
	font-weight: 500;
	font-size: 24px;
	line-height: 130%;
	margin-bottom: 18px;
}
.text {
	font-weight: 400;
	font-size: 16px;
	line-height: 180%;
	color: var(--black);
	p:not(:last-child) {
		margin-bottom: 16px;
	}
}
// Спиннер
@keyframes spinner {
  to {transform: rotate(360deg);}
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: var(--cyan);
  border-right-color: #fff;
  border-bottom-color: var(--orange);
  animation: spinner 1s linear infinite;
}

// Компоненты
@import "./includes/header";
@import "./includes/mainscreen";
@import "./includes/footer";
@import "./includes/popup";
@import "./includes/scroll-top";
@import "./includes/short";
@import "./includes/objects";
@import "./includes/adv";
@import "./includes/partners";
@import "./includes/invest";
@import "./includes/bar";
@import "./includes/rent";
@import "./includes/object";
@import "./includes/pagination";
@import "./includes/news";
@import "./includes/article";
@import "./includes/contacts";
@import "./includes/photos";
@import "./includes/gallery";
@import "./includes/videos";
