.pagination {
	margin-bottom: 60px;
	@media (max-width: 992px) {
		margin-bottom: 30px;
	}

	&__body {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__list {
		display: flex;
		align-items: center;
		margin: 0 -6px;
	}

	&__item {
		margin: 0 6px;
		@media (any-hover: hover) {
			&:hover {
				.pagination__link {
					background: var(--orange);
					color: #fff;
				}
			}
		}
	}

	&__link {
		width: 54px;
		height: 54px;
		color: var(--black);
		font-size: 15px;
		line-height: 130%;
		font-weight: 500;
		background: #f2f5fd;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		transition: all 0.3s ease 0s;
		&.pagination__link--active {
			background: var(--cyan);
			color: #fff;
		}
		@media (max-width: 992px) {
			height: 40px;
			width: 40px;
		}
	}
}
