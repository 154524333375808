.videos {
	padding: 50px 0 0 0;
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		@media (max-width:767px) {
			margin: 0 -10px;
		}
	}

	&__item {
		flex: 0 0 33.333%;
		padding: 0 15px 45px 15px;
		@media (max-width:992px) {
			flex: 0 0 50%;
			padding: 0 15px 30px 15px;
		}
		@media (max-width:767px) {
			padding: 0 10px 30px 10px;
		}
		@media (max-width:500px) {
			flex: 0 0 100%;
		}
	}

	&__image {
		filter: drop-shadow(5px 15px 40px rgba(30, 35, 66, 0.15));
		overflow: hidden;
		position: relative;
		padding: 0 0 61% 0;
		margin-bottom: 30px;
		iframe {
			transition: transform 0.5s ease 0s;
			transform: scale3d(1, 1, 1);
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width:992px) {
			margin-bottom: 10px;
		}
	}

	&__title {
		display: block;
		font-weight: 500;
		font-size: 18px;
		line-height: 130%;
		text-align: center;
		color: var(--black);
		transition: color 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				color: var(--orange);
			}
		}
	}
}