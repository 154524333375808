.objects {
	clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0% 100%);
	padding: 210px 0 60px 0;
	background: #f2f5fd;
	background-blend-mode: multiply;
	&.objects--object{
		background: transparent;
	}
	@media (max-width:1024px) {
		clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
		padding: 50px 0 120px 0;
	}
	@media (max-width:500px) {
		padding: 30px 0 100px 0;
	}

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 50px;
		@media (max-width:1365px) {
			margin-bottom: 80px;
		}
		@media (max-width:1024px) {
			margin-bottom: 50px;
		}
		@media (max-width:767px) {
			flex-direction: column;
			margin-bottom: 30px;
			align-items: flex-start;
		}
	}

	&__subtitle {
		margin-right: 20px;
		@media (max-width:767px) {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}

	&__link {
		font-weight: normal;
		font-size: 15px;
		line-height: 130%;
		color: var(--cyan);
		display: flex;
		align-items: center;
		&:before {
			font-size: 20px;
			display: block;
			margin-right: 10px;
		}
		span {
			text-decoration-line: underline;
		}
		@media (any-hover: hover) {
			&:hover {
				span {
					text-decoration: none;
				}
			}
		}
	}

	&__swiper-wrap{
		position: relative;
		.swiper__button-next{
			position: absolute;
			top: 50%;
			right: -60px;
			z-index: 1;
			transform: translate(0,-50%);
			cursor: pointer;
			svg{
				width: 40px;
				height: 40px;
				transition: fill 0.3s ease 0s;
				@media (any-hover: hover) {
					&:hover {
						fill: var(--orange);
					}
				}
			}
			@media (max-width:1365px) {
				top: -60px;
				right: 0;
				transform: translate(0,0);
			}
			@media (max-width:1024px) {
				top: 110%;
			}
			@media (max-width:500px) {
				top: 105%;
			}
		}
	
		.swiper__button-prev{
			position: absolute;
			top: 50%;
			left: -60px;
			z-index: 1;
			cursor: pointer;
			transform: translate(0,-50%);
			svg{
				width: 40px;
				height: 40px;
				transition: fill 0.3s ease 0s;
				@media (any-hover: hover) {
					&:hover {
						fill: var(--orange);
					}
				}
			}
			@media (max-width:1365px) {
				top: -60px;
				left: 0;
				transform: translate(0,0);
			}
			@media (max-width:1024px) {
				top: 110%;
			}
			@media (max-width:500px) {
				top: 105%;
			}
		}
	}

	&__image {
		display: block;
		overflow: hidden;
		position: relative;
		padding: 0 0 62% 0;
		margin-bottom: 25px;
		transition: filter 0.5s ease 0s;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
			transition: transform 0.5s ease 0s;
			transform: scale3d(1, 1, 1);
		}
		@media (any-hover: hover) {
			&:hover {
				img{
					transform: scale3d(1.03, 1.03, 1.03);
				}
			}
		}
	}

	&__address {
		font-weight: 400;
		font-size: 14px;
		line-height: 130%;
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		span {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: var(--cyan);
			font-size: 14px;
			color: #fff;
			margin-right: 12px;
		}
	}

	&__caption {
		font-weight: 500;
		font-size: 20px;
		line-height: 130%;
		color: var(--black);
		margin-bottom: 12px;
		display: inline-block;
		position: relative;
		&:before {
			content: "";
			height: 2px;
			width: 100%;
			transform: scaleX(0);
			transition: transform 0.3s ease 0s;
			transform-origin: right;
			position: absolute;
			bottom: -3px;
			left: 0;
			background: var(--cyan);
		}
		@media (any-hover: hover) {
			&:hover {
				&:before {
					transform: scaleX(1);
					transform-origin: left;
				}
			}
		}
	}

	&__text {
		font-weight: normal;
		font-size: 13px;
		line-height: 170%;
	}
}
